<template>
  <div>
    <v-container v-if="badges && badges.length > 0" class="mt-0">
      <v-row>
        <v-col
          v-for="(item, idx) in badges"
          :key="idx"
          cols="6"
          sm="3"
          md="2"
          lg="2"
          class="text-center pa-4"
        >
          <v-sheet :elevation="1" color="white" @click="$emit('click', item)">
            <strong class="mb-2 d-block" :style="`color:${item.count == 0 ? '#aaa' : 'inherit'};`">{{ item.name }}</strong>
            <img :src="item.img" class="pa-2" :style="`max-width: 100%;opacity:${item.count == 0 ? 0.3 : 1};`"/>
            <div v-if="item.count > 0" class="text-muted">
              <v-chip color="accent" small>{{item.count}}x</v-chip> {{$t('events.race.badge-first-rewarded')}}<br/>
              {{ item.first_reward_date | localDate('L')}}
            </div>
            <div v-else class="text-muted">
              {{$t('events.race.badge-not-unlocked')}}
            </div>
            <div>
              <BadgeDetailsDialog :event="event" :race="race" :badgeId="item.id" :detailsType="item.details_type"/>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>

<script>
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";

export default {
  name: "RaceBadgesGrid",
  components: {
    BadgeDetailsDialog,
  },
  props: {
      event: Object,
      race: Object,
      badges: Array,
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
    
  },
  computed: {
  },

};
</script>
<style lang="scss">
</style>

